<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="常用" name="general">
      <el-input v-model="uid" placeholder="UID" @change="onEditUid"></el-input>
      
      <el-row style="margin-top:10px;">
        <el-button>圆角按钮</el-button>
        <el-button  @click="tapReloadDB">reloadDB</el-button>
        <el-button type="warning">重启设备</el-button>
      </el-row>
<el-row>
  </el-row>      
    </el-tab-pane>
    <el-tab-pane label="高级" name="advanced">多级Tabs

<el-tabs v-model="activeName2" @tab-click="handleClick">
    <el-tab-pane label="用户管理" name="cfirst">用户管理</el-tab-pane>
    <el-tab-pane label="配置管理" name="csecond">配置管理</el-tab-pane>
    <el-tab-pane label="角色管理" name="cthird">角色管理</el-tab-pane>
    <el-tab-pane label="定时任务补偿" name="cfourth">定时任务补偿</el-tab-pane>
  </el-tabs>

    </el-tab-pane>
  </el-tabs>

</template>
<script>
import { parse_xml } from '@/common/parsexml'
import Axios from "axios";
import vkbeautify from 'vkbeautify'

export default {
  data() {
    return {
      uid: "",
      activeName: "general",
      activeName2:"",
      formatData:"",
      xmlData:"",
      xml_show:"",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    onEditUid:function(text)
    {
        console.log("uid="+text);
      let cfg=localStorage.getItem("factory");
      let jsonObj=null
      if(cfg)  
      {
          jsonObj=JSON.parse(cfg);
          jsonObj.uid=text;
      }
      else{
          jsonObj={uid:text};
      }

      localStorage.setItem("factory",JSON.stringify(jsonObj));
    },

    async reloadDB() {
        let ack = await Axios.get(
          "http://192.168.1.3/Iot.ReloadDB.xml"
          //"https://iot.jjyip.com/proc.xml?url=IotServer[PWR.00463NJU]"
          );
        console.log("ack=",ack.data);
        //this.xml_show=vkbeautify.xml(xmlSource)
        //this.xmlData=ack.data;
        //this.formatData = parse_xml(ack.data)

        /*
https://www.jianshu.com/p/216381349340
xml转为json
*/

        return ack;
    },
    tapReloadDB:function(){
        console.log("reloadDB");
        this.reloadDB();
    },
  },

  mounted:function(){
      let cfg=localStorage.getItem("factory");
      if(cfg)
      {
          let jsonObj=JSON.parse(cfg);
          this.uid = jsonObj.uid;
      }
  }
};
</script>

<style >
   .code-string{color:#993300;}
   .code-number{color:#cc00cc;}
   .code-boolean{color:#000033;}
   .code-null{color:magenta;}
   .code-key{color:#003377;font-weight:bold;}
 </style>
